<template>
    <div>
        <Toast />
        <div class="card">
            <div class="p-grid p-fluid">
                <div class="p-col-12">
                    <h5>{{ $t('ApplicationManagement.search.searchHeading') }}</h5>
                    <div class="p-formgrid p-grid">
                        <div class="p-field p-col-12 p-md-3">
                            <label for="type">{{ $t('ApplicationManagement.search.title') }}</label>
                            <InputText id="googlurl" type="text" :placeholder="$t('ApplicationManagement.search.SearchbyTitle')" v-model="title" />
                        </div>
                        <!-- <div class="p-field p-col-12 p-md-3">
                            <label for="pass">Country</label>
                            <Dropdown v-model="country_id" :options="dropdownCountryValues" optionValue="id"
                                optionLabel="country_name" placeholder="Select " />
                        </div> -->
                        <div class="p-field p-col-12 p-md-3">
                            <label for="pass">{{ $t('ApplicationManagement.search.Application_Status') }}</label>
                            <Dropdown v-model="application_status" :options="dropdownstatusAppValues" optionValue="value" optionLabel="name" :placeholder="$t('ApplicationManagement.search.Select')" />
                        </div>

                        <div class="p-field p-col-12 p-md-3">
                            <label for="pass">{{ $t('Applications.search.ApplicationStartDates') }}</label>
                            <Calendar v-model="release_date" :manualInput="false" placeholder="YYYY/MM/DD H:M:S"
                                :showIcon="true" :showTime="true" :showSeconds="true">
                            </Calendar>

                        </div>
                        <div class="p-field p-col-12 p-md-3">
                            <label for="pass">{{ $t('Applications.search.ApplicationEndtDates') }}</label>
                            <Calendar v-model="expire_date" :manualInput="false" placeholder="YYYY/MM/DD H:M:S"
                                :showIcon="true" :showTime="true" :showSeconds="true">
                            </Calendar>
                        </div>
                        <div class="p-field p-col-12 p-md-3">
                            <label for="pass">{{ $t('Applications.search.VotingStartDates') }}</label>
                            <Calendar v-model="voting_start_date" :manualInput="false" placeholder="YYYY/MM/DD H:M:S"
                                :showIcon="true" :showTime="true" :showSeconds="true">
                            </Calendar>
                        </div>

                        <div class="p-field p-col-12 p-md-3">
                            <label for="pass">{{ $t('Applications.search.VotingEndDates') }}</label>
                            <Calendar v-model="voting_end_date" :manualInput="false" placeholder="YYYY/MM/DD H:M:S"
                                :showIcon="true" :showTime="true" :showSeconds="true">
                            </Calendar>
                        </div>
                    </div>
                </div>
            </div>
            <div class="p-d-flex p-jc-between p-ai-lg-center p-ai-start p-mt-6 p-flex-column p-flex-lg-row">
                <div class="p-mb-4 p-mb-lg-0">
                    <Button :label="$t('button.today')" icon="pi pi-calendar" iconPos="left" class="p-button p-button-sm p-mr-2 p-mb-2" @click="today"></Button>
                    <Button :label="$t('button.lastWeek')" icon="pi pi-calendar" iconPos="left" class="p-button p-button-sm p-mr-2 p-mb-2" @click="lastweek"></Button>
                    <Button :label="$t('button.lastMonth')" icon="pi pi-calendar" iconPos="left" class="p-button p-button-sm p-mr-2 p-mb-2" @click="lastmonth"></Button>
                    <Button :label="$t('button.last6Months')" icon="pi pi-calendar" iconPos="left" class="p-button p-button-sm p-mr-2 p-mb-2" @click="lastsixmonth"></Button>
                    <!-- <Button :label="$t('button.lastYear')" icon="pi pi-calendar" iconPos="left" class="p-button p-button-outlined p-button-sm p-mr-2 p-mb-2" @click="lastyear"></Button> -->
                </div>
                <div>
                    <Button :label="$t('button.search')" icon="pi pi-search" iconPos="left" class="p-button p-button-sm p-mr-2 p-mb-2" @click="searchvotemanagement()"></Button>
                    <Button :label="$t('button.reset')" icon="pi pi-replay" iconPos="left" class="p-button p-button-sm p-mr-2 p-mb-2" @click="resetvotemanagement()"></Button>
                </div>
            </div>
        </div>
        <div class="card">
            <div class="p-grid">
                <div class="p-col-12">
                    <div class="p-d-flex p-jc-between p-mb-2">
                        <h4>{{ $t('ApplicationManagement.list.Application_List') }}</h4>
                    </div>
                    <DataTable
                        :value="products"
                        :paginator="true"
                        class="p-datatable-gridlines"
                        :rows="10"
                        dataKey="id"
                        :rowHover="true"
                        :loading="loading1"
                        :filters="filters1"
                        responsiveLayout="scroll"
                        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                        :rowsPerPageOptions="[10, 25, 50]"
                        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
                    >
                        <ConfirmDialog group="dialog" />

                        <template #empty>{{$('Nodata')}}</template>
                        <template #loading>Loading data. wait.</template>

                        <Column field="#" header="#" style="min-width: 5rem">
                            <template #body="{ index }">
                                <span class="p-column-title">Sl. No.</span>
                                {{ index + 1 }}
                            </template>
                        </Column>
                        <Column field="companyname" :header="$t('ApplicationManagement.list.Title')" style="min-width: 13rem">
                            <template #body="{ data }">
                                <span class="p-column-title">Title</span>
                                {{ data.title }}
                            </template>
                        </Column>
                        <Column field="product" :header="$t('ApplicationManagement.list.Application_Start_Date')" style="min-width: 13rem">
                            <template #body="{ data }">
                                <span class="p-column-title">Application Start Date</span>
                                {{ UTCdateformat(data.release_date) }}
                            </template>
                        </Column>
                        <Column field="service" :header="$t('ApplicationManagement.list.Application_End_Date')" style="min-width: 12rem">
                            <template #body="{ data }">
                                <span class="p-column-title">Application End Date</span>
                                {{ UTCdateformat(data.expire_date) }}
                            </template>
                        </Column>
                        <Column field="Status" :header="$t('ApplicationManagement.list.Country')" style="min-width: 12rem">
                            <template #body="{ data }">
                                <span class="p-column-title">Country</span>

                                {{ data.country_name }}
                            </template>
                        </Column>

                        <Column field="Creation-Date" :header="$t('ApplicationManagement.list.Voting_start_Date')" style="min-width: 12rem">
                            <template #body="{ data }">
                                <span class="p-column-title">Voting start Date</span>
                                {{ UTCdateformat(data.voting_start_date) }}
                            </template>
                        </Column>

                        <Column field="Creation-Date" :header="$t('ApplicationManagement.list.Voting_End_Date')" style="min-width: 12rem">
                            <template #body="{ data }">
                                <span class="p-column-title">Voting End Date</span>

                                {{ UTCdateformat(data.voting_end_date) }}
                            </template>
                        </Column>

                        <Column field="Creation-Date" :header="$t('ApplicationManagement.list.Max_no_of_Applicants')" style="min-width: 13rem">
                            <template #body="{ data }">
                                <span class="p-column-title">Max. no. of Applicants</span>
                                {{ data.max_user_apply }}
                            </template>
                        </Column>

                        <Column field="Creation-Date" :header="$t('ApplicationManagement.list.no_of_Applicants')" style="min-width: 12rem">
                            <template #body="{ data }">
                                <span class="p-column-title">no. of Applicants</span>
                                {{ data.applicant_count }}
                            </template>
                        </Column>

                        <Column field="Creation-Date" :header="$t('ApplicationManagement.list.No_of_selected_applicants')" style="min-width: 14rem">
                            <template #body="{ data }">
                                <span class="p-column-title">No. of selected applicants</span>
                                {{ data.shortlisted_applicant_count }}
                            </template>
                        </Column>
                        <Column field="Application Status" :header="$t('ApplicationManagement.list.Application_Status')" style="min-width: 14rem">
                            <template #body="{ data }">
                                <span class="p-column-title">Application Status</span>
                                <span v-if="cur_date > startdateformat(data.expire_date) && cur_date < startdateformat(data.voting_start_date)">
                                    <p>Shortlisting Ongoing</p>
                                </span>

                                <span v-else-if="cur_date >= startdateformat(data.voting_start_date) && cur_date <= startdateformat(data.voting_end_date)">
                                    <p>Voting Ongoing</p>
                                </span>

                                <span v-else-if="cur_date >= startdateformat(data.release_date) && cur_date <= startdateformat(data.expire_date)">
                                    <p>Registration Ongoing</p>
                                </span>

                                <span v-else-if="cur_date > startdateformat(data.expire_date)">
                                    <p>Expired</p>
                                </span>

                                <span v-else-if="cur_date < startdateformat(data.release_date)">
                                    <p>Yet to Start</p>
                                </span>

                                <span v-else>
                                    <p>--</p>
                                </span>
                                <!-- {{ data.application_status }} -->
                            </template>
                        </Column>

                        <Column field="Actions" :header="$t('ApplicationManagement.list.Action')">
                            <template #body="{ data }">
                                <span class="p-column-title">Actions</span>
                                <p style="display: none">{{ data.status }}</p>
                                <div style="display: flex">
                                    <router-link :to="'/ManagerApplicationlistView/' + data.id">
                                        <Button label="info" class="n-wrap p-button-outlined p-button-info p-mb-2">
                                            <i class="pi pi-eye p-mr-2"></i>
                                        </Button>
                                    </router-link>
                                </div>
                            </template>
                        </Column>
                    </DataTable>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { useRoute } from 'vue-router';
import VotemanagementService from '../../../service/API/SuperAdmin/VotemanagementService.js';
import axios from 'axios';
import moment from 'moment';
export default {
    data() {
        return {
            // dropdownValues: [{ name: 'nft',code:'NFT' }, { name: 'card_news',code:'Card News' }, { name: 'media_press',code:'Media press' }, { name: 'de_fi_services',code:'De-Fi Services' }],
            dropdownstatusValues: [
                { name: 'Active', code: 'active' },
                { name: 'Inactive', code: 'deleted' },
            ],
            dropdownstatusAppValues: [
                { name: 'Shortlisting Ongoing', value: 'shortlisting_ongoing' },
                { name: 'Voting Ongoing', value: 'voting_ongoing' },
                { name: 'Registration Ongoing', value: 'registration_ongoing' },
                { name: 'Yet to Start', value: 'yet_to_start' },
                { name: 'Expired', value: 'expired' },
            ],
            serial: 0,
            dropdownstatusValue: null,
            dropdownstatusAppValue: null,

            isModalVisible: false,
            dropdownCountryValues: '',
            dropdownCountryValue: null,
            dropdownBoardCategoryValues: '',
            dropdownBoardCategoryValue: null,
            products: null,
            loading1: true,
            deletedID: null,
            sl_no: '',
            title: '',
            country_id: '',
            category_id: '',
            post_date: '',
            status: '',
            total: '',
            id: '',
            description: '',
            posted_date: '',
            from_date: '',
            to_date: '',
            searchdate: '',
            searchenddate: '',
            searchvotingdate: '',
            searchvotingenddate: '',
            dateRange: '',
            dateVotingRange: '',
            release_date: '',
            expire_date: '',
            voting_start_date: '',
            voting_end_date: '',
            application_status: '',
            votingEndDate: '',
            votingStartDate: '',
            expireDate: '',
            releaseDate: '',
            cur_date: moment(new Date()).format('YYYY/MM/DD HH:mm:ss'),
        };
    },
    created() {
        this.votemanagementService = new VotemanagementService();
    },
    mounted() {
        const route = useRoute();
        console.log(route.params);
        this.getVotemanagement();

        this.votemanagementService
            .getCountrydropdown()
            .then((data) => {
                this.dropdownCountryValues = data.data.data.country;
                // this.products = data;
                this.loading1 = false;
                // this.products.forEach((customer) => (customer.createdDate = new Date(customer.createdDate)));
                console.log(this.dropdownCountryValues);
            })
            .catch((err) => console.log(err));
    },
    methods: {
        getVotemanagement() {
            const BulletParams = {
                // page: 1,
                // limit: this.pagination.pagination_limit,
                title: this.title,
                country_id: this.country_id,
                category_id: this.category_id,
                release_date: this.release_date,
                expire_date: this.expire_date,
                status: this.status,
                application_status: this.application_status,
                voting_start_date: this.voting_start_date,
                voting_end_date: this.voting_end_date,
            };
            this.loading1 = true;
            this.votemanagementService
                .getvotemanagementList(BulletParams)
                .then((res) => {
                    this.products = res.data.data.application;
                    this.totalrecords = res.data.total_records;
                    // this.setPagination(res.data.current_page, res.data.total_pages === 0 ? 1 : res.data.total_pages, res.data.total_records);
                    this.loading1 = false;
                    console.log(res);
                })
                .catch((err) => console.log(err));
        },

        addDay(val) {
            const date = new Date(val);
            var dd = date.getDate();
            var mm = date.getMonth() + 1;
            var yyyy = date.getFullYear();
            if (dd < 10) {
                dd = '0' + dd;
            }
            if (mm < 10) {
                mm = '0' + mm;
            }
            return (val = yyyy + '-' + mm + '-' + dd);
        },

        resetvotemanagement() {
            this.title = '';
            this.country_id = '';
            this.voting_end_date = '';
            this.voting_start_date = '';
            this.application_status = '';
            this.release_date = '';
            this.expire_date ='';
            // this.calendarValue1 = '';
            this.loading1 = true;
            // window.location.reload();
            this.getVotemanagement();
        },
        today() {
            let today = new Date();
            let firstDate = new Date();
            firstDate.setDate(today.getDate());
            this.dateRange = [firstDate, today];
            this.searchvotemanagement();
        },
        lastweek() {
            let today = new Date();
            let firstDate = new Date();
            firstDate.setDate(today.getDate() - 7);
            this.dateRange = [firstDate, today];
            this.searchvotemanagement();
        },
        lastmonth() {
            let today = new Date();
            let firstDate = new Date();
            firstDate.setDate(today.getDate() - 31);
            this.dateRange = [firstDate, today];
            this.searchvotemanagement();
        },
        lastsixmonth() {
            let today = new Date();
            let firstDate = new Date();
            firstDate.setDate(today.getDate() - 182);
            this.dateRange = [firstDate, today];
            this.searchvotemanagement();
        },

        searchvotemanagement() {
            if (this.title === '' && this.country_id === '' && this.release_date === '' && this.expire_date === '' && this.voting_start_date === '' && this.voting_end_date === '' && this.application_status === '') {
                this.$toast.add({ severity: 'error', summary: '에러 메시지', detail: '검색창을 입력해주세요.', life: 2000 });
            }else if (this.release_date !== '' && this.expire_date == '') {
                // console.log(this.productCategoryStatus);
                this.$toast.add({ severity: 'error', summary: '에러 메시지', detail: '일자 기간을 설정해 주세요.', life: 3000 });
            }
            else if (this.release_date == '' && this.expire_date !== '') {
                // console.log(this.productCategoryStatus);
                this.$toast.add({ severity: 'error', summary: '에러 메시지', detail: '일자 기간을 설정해 주세요.', life: 3000 });
            }
            else if (this.voting_start_date !== '' && this.voting_end_date == '') {
                // console.log(this.productCategoryStatus);
                this.$toast.add({ severity: 'error', summary: '에러 메시지', detail: '일자 기간을 설정해 주세요.', life: 3000 });
            }
            else if (this.voting_start_date == '' && this.voting_end_date !== '') {
                // console.log(this.productCategoryStatus);
                this.$toast.add({ severity: 'error', summary: '에러 메시지', detail: '일자 기간을 설정해 주세요.', life: 3000 });
            } 
            else  {
                if (this.release_date != '') {
                    this.releaseDate = this.formatRangeDate(this.release_date)
                } else {
                    this.releaseDate = ""
                }
                if (this.expire_date != '') {
                    this.expireDate = this.formatRangeDate(this.expire_date)
                } else {
                    this.expireDate = ""
                }
                if (this.voting_start_date != '') {
                    this.votingStartDate = this.formatRangeDate(this.voting_start_date)
                } else {
                    this.votingStartDate = ""
                }
                if (this.voting_end_date != '') {
                    this.votingEndDate = this.formatRangeDate(this.voting_end_date)
                } else {
                    this.votingEndDate = ""
                }
                const BulletinParams = {
                    // page: 1,
                    // limit: this.pagination.pagination_limit,
                    title: this.title,
                    country_id: this.country_id,
                    category_id: this.category_id,
                    release_date: this.releaseDate,
                    expire_date: this.expireDate,
                    status: this.status,
                    application_status: this.application_status,
                    voting_start_date: this.votingStartDate,
                    voting_end_date: this.votingStartDate,
                };
                this.votemanagementService
                    .getvotemanagementList(BulletinParams)
                    .then((res) => {
                        this.products = res.data.data.application;
                        this.total = res.data.data.total;
                        this.loading1 = false;
                        console.log(res);
                    })
                    .catch(() => {
                        this.products = [];
                        this.loading1 = false;
                    });
            }
        },

        dateformat(value) {
            if (value) {
                return moment(String(value)).locale('ko').format('ll - LTS');
            }
        },

        startdateformat(value) {
            if (value) {
                return moment.utc(value).format('YYYY/MM/DD HH:mm:ss');
            }
        },
        formatRangeDate(date) {
            if (date === undefined || date === '') {
                return '';
            }
            return moment(new Date(date)).format('YYYY-MM-DD HH:mm:ss');
        },
        UTCdateformat(value) {
            if (value) {
                return moment.utc(value).locale('ko').format('ll - LTS');

            }
        },
        
        deleteNote: function (id) {
            console.log(id);
            var self = this;
            this.$confirm.require({
                group: 'dialog',
                header: 'Confirm',
                message: 'Are you sure you want to delete?',
                icon: 'pi pi-trash',
                acceptLabel: 'Confirm',
                rejectLabel: 'cancellation',
                accept: () => {
                    axios({ method: 'delete', url: '/asnhist-dev/api/v1/admin/application/delete', data: { deleteIdArray: id } }).then(function (response) {
                        console.log(response);
                        self.getVotemanagement();
                    });
                    this.$toast.add({ severity: 'info', summary: '삭제되었습니다.', detail: '성공적으로 삭제되었습니다.', life: 3000 });
                },
                reject: () => {
                    this.$toast.add({ severity: 'error', summary: '에러 메시지', detail: 'You have rejected', life: 3000 });
                },
            });
        },
    },
};
</script>

<style scoped lang="scss">
::v-deep(.p-datatable-frozen-tbody) {
    font-weight: bold;
}

::v-deep(.p-datatable-scrollable .p-frozen-column) {
    font-weight: bold;
}

::v-deep(.p-progressbar) {
    height: 0.5rem;
    background-color: #d8dadc;

    .p-progressbar-value {
        background-color: #607d8b;
    }
}

::v-deep(.p-datatable .p-sortable-column .p-column-title) {
    display: block;
}

.p-datatable .p-column-filter {
    display: none;
}

.table-header {
    display: flex;
    justify-content: space-between;
}

.customer-badge {
    border-radius: 2px;
    padding: 0.25em 0.5rem;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 12px;
    letter-spacing: 0.3px;

    &.status-qualified {
        background: #c8e6c9;
        color: #256029;
    }

    &.status-unqualified {
        background: #ffcdd2;
        color: #c63737;
    }

    &.status-negotiation {
        background: #feedaf;
        color: #8a5340;
    }

    &.status-new {
        background: #b3e5fc;
        color: #23547b;
    }

    &.status-renewal {
        background: #eccfff;
        color: #694382;
    }

    &.status-proposal {
        background: #ffd8b2;
        color: #805b36;
    }
}

.p-progressbar-value.ui-widget-header {
    background: #607d8b;
}

@media (max-width: 640px) {
    .p-progressbar {
        margin-top: 0.5rem;
    }
}

.product-image {
    width: 100px;
    height: 50px;
    // box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}

.orders-subtable {
    padding: 1rem;
}

.product-badge {
    border-radius: 2px;
    padding: 0.25em 0.5rem;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 12px;
    letter-spacing: 0.3px;

    &.status-instock {
        background: #c8e6c9;
        color: #256029;
    }

    &.status-outofstock {
        background: #ffcdd2;
        color: #c63737;
    }

    &.status-lowstock {
        background: #feedaf;
        color: #8a5340;
    }
}

.order-badge {
    border-radius: 2px;
    padding: 0.25em 0.5rem;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 12px;
    letter-spacing: 0.3px;

    &.order-delivered {
        background: #c8e6c9;
        color: #256029;
    }

    &.order-cancelled {
        background: #ffcdd2;
        color: #c63737;
    }

    &.order-pending {
        background: #feedaf;
        color: #8a5340;
    }

    &.order-returned {
        background: #eccfff;
        color: #694382;
    }
}

.p-datatable {
    .p-sortable-column {
        &.p-highlight {
            &:hover {
                background: rgb(216 217 243);
                color: #464df2;
            }
        }
    }
}

.true-icon {
    color: #256029;
}

.false-icon {
    color: #c63737;
}

.w-100 {
    width: 100%;
}

.p-button {
    background: #000000;
    border: 1px solid #0a0a0a;
}

.p-button.p-button-info.p-button-outlined,
.p-buttonset.p-button-info > .p-button.p-button-outlined,
.p-splitbutton.p-button-info > .p-button.p-button-outlined {
    background-color: transparent;
    color: #171718;
    border: 0px solid;
}

.p-button.p-button-info.p-button-outlined:hover,
.p-buttonset.p-button-info > .p-button.p-button-outlined,
.p-splitbutton.p-button-info > .p-button.p-button-outlined:hover {
    background-color: transparent;
    color: #171718;
    border: 0px solid;
}

.p-button.p-button-info.p-button-outlined:enabled:active,
.p-buttonset.p-button-info > .p-button.p-button-outlined:enabled:active,
.p-splitbutton.p-button-info > .p-button.p-button-outlined:enabled:active {
    background: rgba(2, 136, 209, 0.16);
    color: #171718;
    border: 0px solid;
}

.p-button.p-button-help.p-button-outlined,
.p-buttonset.p-button-help > .p-button.p-button-outlined,
.p-splitbutton.p-button-help > .p-button.p-button-outlined {
    background-color: transparent;
    color: #171718;
    border: 0px solid;
}

.p-button.p-button-help.p-button-outlined:hover,
.p-buttonset.p-button-help > .p-button.p-button-outlined,
.p-splitbutton.p-button-help > .p-button.p-button-outlined:hover {
    background-color: transparent;
    color: #171718;
    border: 0px solid;
}

.p-button.p-button-danger.p-button-outlined,
.p-buttonset.p-button-danger > .p-button.p-button-outlined,
.p-splitbutton.p-button-danger > .p-button.p-button-outlined {
    background-color: transparent;
    color: #171718;
    border: 0px solid;
}

.p-button.p-button-danger.p-button-outlined:hover,
.p-buttonset.p-button-danger > .p-button.p-button-outlined,
.p-splitbutton.p-button-danger > .p-button.p-button-outlined:hover {
    background-color: transparent;
    color: #171718;
    border: 0px solid;
}
</style>